import {tns} from "tiny-slider";

const specialSlidersWrappers = document.querySelectorAll('.special-products-slider__items-wrapper')
const $specialSlidersTabsHeader = document.querySelector('.special-products-slider__header')

specialSlidersWrappers.forEach(createSpecialSlider)

if ($specialSlidersTabsHeader) {
    createSpecialTabs()
}

function createSpecialSlider($wrapper) {

    const $slider = $wrapper.querySelector('.special-products-slider__items')
    const $prev = $wrapper.querySelector('.special-products-slider__items-button--prev')
    const $next = $wrapper.querySelector('.special-products-slider__items-button--next')

    const navigationSlider = tns({
            container: $slider,
            items: 1,
            mouseDrag: true,
            controls: true,
            prevButton: $prev,
            nextButton: $next,
            loop: false,
            // gutter: 28,
            autoplay: false,
            responsive: {
                1280: {
                    items: 4
                }
            }
        }
    )
}

function createSpecialTabs($button) {
    const $buttons = document.querySelectorAll('.special-products-slider__header-item')
    const $tabs = document.querySelectorAll('.special-products-slider__items-wrapper')
    const buttonActiveCLass = 'special-products-slider__header-item--active'
    const tabActiveCLass = 'special-products-slider__items-wrapper--active'

    $buttons.forEach(($currentButton) => {
        const target = $currentButton.dataset.specialSliderTarget

        $currentButton.addEventListener('click', () => {
            $tabs.forEach(($tab) => {
                $tab.classList.remove(tabActiveCLass)
            })

            $buttons.forEach(($button) => {
                $button.classList.remove(buttonActiveCLass)
            })

            $currentButton.classList.add(buttonActiveCLass)
            document.querySelector(`[data-special-slider="${target}"]`).classList.add(tabActiveCLass)
        })
    })


}
