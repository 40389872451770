import {axios} from "../index";

let submits = Array.from(document.querySelectorAll('[data-submit-form]'));

submits.forEach(($submitForm) => {
    submitForm($submitForm)
})

function submitForm($wrapper) {
    const classPrefix = 'submit-form'
    const dataPrefix = 'data-submit-form'

    const inputs = $wrapper.querySelectorAll(`[${dataPrefix}-input]`)
    const $button = $wrapper.querySelector(`[${dataPrefix}-button]`)
    const $error = $wrapper.querySelector(`[${dataPrefix}-error]`)
    let recipient = ['marketing']

    if ($wrapper.dataset.submitFormRecipient) {
        recipient = $wrapper.dataset.submitFormRecipient.split(',')
    }



    $button.addEventListener('click', () => {
        if ($button.classList.contains('form-check__button--disabled')) {
            return
        }

        const answers = []
        const name = $wrapper.dataset.submitForm
        const language = document.getElementsByTagName('html')[0].getAttribute('lang');

        inputs.forEach(($input) => {
            answers.push(`${$input.dataset.submitFormInput}: ${$input.value}`)
        })


        axios.post('/send-mail', {
            name,
            language,
            answers,
            recipient
        })
            .then((response) => {
                inputs.forEach(($input) => {
                    $input.value = ''
                })

                console.log(response.data)

                window.modals.close()
                window.modals.open('success')
            })
            .catch((event) => {
                console.log(event.response.data.message)

                const $errorText = document.querySelector('[data-modal-error-message]')
                $errorText.innerText = event.response.data.message
                window.modals.close()
                window.modals.open('error')
            });


    })
}
