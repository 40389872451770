let $langContainer = document.querySelector('.header__languages-list')
let $headerLang = document.querySelector('.header__languages')

if ($langContainer) {
    let $langContainerItems = $langContainer.querySelectorAll('.header__languages-list-item')
    let $curLangItem = $headerLang.querySelector('.header__languages-button')
    let pathname = window.location.pathname;


    checkCurrentHrefLangPref(pathname, $curLangItem)

    pathname = getClearPath(pathname);
    $langContainerItems.forEach($langSelectItem => {
        curLang = $langSelectItem.dataset.lang;
        $langSelectItem.href = getLocaleHref(curLang, pathname);
    })

    function checkCurrentHrefLangPref(pathname, $curLangItem) {
        let hrefStep = pathname.split('/');

        if (hrefStep[1] === 'ru') {
            $curLangItem.innerText = 'RU'
        } else if (hrefStep[1] === 'en') {
            $curLangItem.innerText = 'EN'
        } else {
            $curLangItem.innerText = 'UA'
        }
    }
    function getClearPath(pathname) {
        let hrefStep = pathname.split('/');
        if (hrefStep[1] === 'ru') {
            pathname = pathname.replace('/ru', '');
        }
        if (hrefStep[1] === 'en') {
            pathname = pathname.replace('/en', '');
        }

        return pathname;
    }
    function getLocaleHref(locale, pathname) {
        if (locale === '/ua')
        {
            locale = ''
        }
        return window.location.origin + locale + pathname;
    }

}
