import {mobileBreakpoint} from "../index";

const $header = document.querySelector('.header')

if ($header) {
    toggleHeader($header)
    toggleSubmenu($header)
    toggleLanguages($header)
}

function toggleHeader($header) {
    const $toggleButton = $header.querySelector('.header__mobile-panel-button')
    const $background = $header.querySelector('.header__shadow')

    const activeHeaderBlockClass = 'header--active'

    $toggleButton.addEventListener('click', () => {
        $header.classList.add(activeHeaderBlockClass)
    })
    $background.addEventListener('click', () => {
        $header.classList.remove(activeHeaderBlockClass)
    })
}

function toggleSubmenu($header) {
    const $headerSubmenuItems = $header.querySelectorAll('.header__menu-item-link')

    const activeItemClass = 'header__menu-item--active'
    const itemClass = 'header__menu-item'

    $headerSubmenuItems.forEach(($headerSubmenuItemLink) => {
        $headerSubmenuItemLink.addEventListener('click', (event) => {
            if (window.innerWidth < mobileBreakpoint) {
                console.log($headerSubmenuItemLink)

                if ($headerSubmenuItemLink.classList.contains('header__menu-item-link--direct')) {
                    return
                }

                event.preventDefault()
            }

            const isActive = $headerSubmenuItemLink.closest(`.${itemClass}`).classList.contains(activeItemClass)

            $headerSubmenuItems.forEach(($headerSubmenuItemLink) => {
                $headerSubmenuItemLink.closest(`.${itemClass}`).classList.remove(activeItemClass)
            })

            if (!isActive) {
                $headerSubmenuItemLink.closest(`.${itemClass}`).classList.add(activeItemClass)
            }

        })
    })
}

function toggleLanguages($header) {
    const $wrapper = $header.querySelector('.header__languages')
    const $list = $header.querySelector('.header__languages-list')

    const activeListClass = 'header__languages-list--active'
    const buttonClass = '.header__languages-button'

    document.addEventListener('click', (event) => {
        const $target = event.target

        let buttonClick = false

        if ($target.classList.contains(buttonClass) || $wrapper.contains($target)) {
            buttonClick = true
        }

        if (!buttonClick) {
            $list.classList.remove(activeListClass)
            return
        }

        if ($list.classList.contains(activeListClass)) {
            $list.classList.remove(activeListClass)
        } else {
            $list.classList.add(activeListClass)
        }

    })
}
