const $faqItems = document.querySelectorAll('.faq__item')
const faqItemActiveClass = 'faq__item--active'

$faqItems.forEach(($faqItem) => {

    const $header = $faqItem.querySelector('.faq__item-header')

    $header.addEventListener('click', () => {
        if ($faqItem.classList.contains(faqItemActiveClass)) {
            closeItems()
        } else {
            closeItems()
            $faqItem.classList.add(faqItemActiveClass)
        }
    })
})

function closeItems() {
    $faqItems.forEach(($faqItem) => {
        $faqItem.classList.remove(faqItemActiveClass)
    })
}
