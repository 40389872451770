import {tns} from "tiny-slider"

const $manufacturerSlider = document.querySelector('.manufacturer-slider')

if ($manufacturerSlider) {
    createManufacturerSlider($manufacturerSlider)
}

function createManufacturerSlider($wrapper) {
    const $slider = $wrapper.querySelector('.manufacturer-slider__items')

    const $prev = $wrapper.querySelector('.manufacturer-slider__button--prev')
    const $next = $wrapper.querySelector('.manufacturer-slider__button--next')

    const buttonInActiveClass = 'manufacturer-slider__button--inactive'

    const slider = tns({
            container: $slider,
            items: 1,
            mouseDrag: false,
            controls: true,
            loop: false,
            prevButton: $prev,
            nextButton: $next,
            responsive: {
                1280: {
                    items: 6
                }
            }
        }
    )

    slider.events.on('indexChanged', (info) => {

        if ((info.index + info.items) === info.slideCount) {
            $next.classList.add(buttonInActiveClass)
            stopAutoplay()
        } else {
            $next.classList.remove(buttonInActiveClass)
        }

        if (info.index === 0) {
            $prev.classList.add(buttonInActiveClass)
        } else {
            $prev.classList.remove(buttonInActiveClass)
        }
    })

    const autoplay = setInterval(() => {
        slider.goTo('next')
    }, 3000)

    const stopAutoplay = () => {
        clearInterval(autoplay)
    }

    $next.addEventListener('click', stopAutoplay)
    $prev.addEventListener('click', stopAutoplay)
    slider.events.on('touchStart', stopAutoplay)
    slider.events.on('dragStart', stopAutoplay)
}
