import {tns} from "tiny-slider"

const $mainSlider = document.querySelector('.main-first-screen')

if ($mainSlider) {
    createMainSlider($mainSlider)
}

// <div className="main-first-screen">
//
//     <div className="main-first-screen__slider-wrapper">
//
//         <div className="main-first-screen__slider">

function createMainSlider($wrapper) {
    const $slider = $wrapper.querySelector('.main-first-screen__slider')
    const $dots = $wrapper.querySelector('.main-first-screen__slider-dots')

    // const $prev = $wrapper.querySelector('.manufacturer-slider__button--prev')
    // const $next = $wrapper.querySelector('.manufacturer-slider__button--next')


    const buttonInActiveClass = 'manufacturer-slider__button--inactive'

    const slider = tns({
            container: $slider,
            items: 1,
            controls: false,
            nav: true,
            navContainer: $dots,
            axis: 'vertical',
            swipeAngle: false,
            speed: 400,
            startIndex: 1,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayButton: false,
            autoplayButtonOutput: false
        }
    )

    // slider.events.on('indexChanged', (info) => {
    //
    //     if ((info.index + info.items) === info.slideCount) {
    //         $next.classList.add(buttonInActiveClass)
    //         stopAutoplay()
    //     } else {
    //         $next.classList.remove(buttonInActiveClass)
    //     }
    //
    //     if (info.index === 0) {
    //         $prev.classList.add(buttonInActiveClass)
    //     } else {
    //         $prev.classList.remove(buttonInActiveClass)
    //     }
    // })
    //
    // const autoplay = setInterval(() => {
    //     slider.goTo('next')
    // }, 3000)
    //
    // const stopAutoplay = () => {
    //     clearInterval(autoplay)
    // }
    //
    // $next.addEventListener('click', stopAutoplay)
    // $prev.addEventListener('click', stopAutoplay)
    // slider.events.on('touchStart', stopAutoplay)
    // slider.events.on('dragStart', stopAutoplay)
}
