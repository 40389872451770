import {tns} from "tiny-slider"

const $productSlider = document.querySelector('.product-card__slider')

if ($productSlider) {
    createProductSlider($productSlider)
}

function createProductSlider($wrapper) {
    const $mainSlider = $wrapper.querySelector('.product-card__slider-main')
    const $navigationSlider = $wrapper.querySelector('.product-card__slider-navigation-items')
    const $navigationSliders = $wrapper.querySelectorAll('.product-card__slider-navigation-item')
    const $prev = $wrapper.querySelector('.product-card__slider-navigation-control--prev')
    const $next = $wrapper.querySelector('.product-card__slider-navigation-control--next')


    const buttonInActiveClass = 'product-card__slider-navigation-control--inactive'

    const mainSlider = tns({
            container: $mainSlider,
            items: 1,
            mouseDrag: false,
            controls: false,
            loop: false
        }
    )

    const navigationSlider = tns({
            container: $navigationSlider,
            items: 2,
            mouseDrag: true,
            controls: true,
            prevButton: $prev,
            nextButton: $next,
            loop: false,
            gutter: 28,
            autoplay: false,
            responsive: {
                1280: {
                    items: 3
                }
            }
        }
    )

    $navigationSliders.forEach(($navigationSlidersItem, index) => {
        $navigationSlidersItem.addEventListener('click', () => {
            mainSlider.goTo(index)
        })
    })


    navigationSlider.events.on('indexChanged', (info) => {

        if ((info.index + info.items) === info.slideCount) {
            $next.classList.add(buttonInActiveClass)
            stopAutoplay()
        } else {
            $next.classList.remove(buttonInActiveClass)
        }

        if (info.index === 0) {
            $prev.classList.add(buttonInActiveClass)
        } else {
            $prev.classList.remove(buttonInActiveClass)
        }

        mainSlider.goTo(info.index)
    })

    const autoplay = setInterval(() => {
        navigationSlider.goTo('next')
    }, 3000)

    const stopAutoplay = () => {
        clearInterval(autoplay)
    }

    $next.addEventListener('click', stopAutoplay)
    $prev.addEventListener('click', stopAutoplay)
    navigationSlider.events.on('touchStart', stopAutoplay)
    navigationSlider.events.on('dragStart', stopAutoplay)
}


const $modalNaturalInformationButtons = document.querySelectorAll('[data-product-card-natural-information]')

$modalNaturalInformationButtons.forEach(($modalNaturalInformationButton) => {
    $modalNaturalInformationButton.addEventListener('click', () => {
        document.querySelector('[data-product-card-natural-information-input]').value = window.location.href
    })
})
