let $tabs = document.querySelectorAll('.tabs')

$tabs.forEach(toggleTabs)

function toggleTabs($wrapper) {
    const $buttons = $wrapper.querySelectorAll('.tabs__navigation-button');
    const $items = $wrapper.querySelectorAll('.tabs__item');

    let itemClass = 'tabs__item'
    let itemActiveClass = 'tabs__item--active'
    let buttonActiveClass = 'tabs__navigation-button--active'

    $buttons.forEach(($button) => {

        $button.addEventListener('click', () => {
            const target = $button.dataset.target
            const $target = $wrapper.querySelector(`.${itemClass}[data-target="${target}"]`)

            $items.forEach(($item) => {
                $item.classList.remove(itemActiveClass)
            })
            $buttons.forEach(($button) => {
                $button.classList.remove(buttonActiveClass)
            })

            $button.classList.add(buttonActiveClass)
            $target.classList.add(itemActiveClass)
        })
    })
}

