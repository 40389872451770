import {tns} from "tiny-slider"

const $newsFilterSlider = document.querySelector('.news__filter')

if ($newsFilterSlider) {
    createNewsFilterSlider($newsFilterSlider)
}

function createNewsFilterSlider($wrapper) {
    const $slider = $wrapper.querySelector('.news__filter-items')
    const $prev = $wrapper.querySelector('.news__filter-button--prev')
    const $next = $wrapper.querySelector('.news__filter-button--next')

    const slider = tns({
            container: $slider,
            items: 1,
            mouseDrag: false,
            controls: false,
            loop: false,
            prevButton: $prev,
            nextButton: $next,
            responsive: {
                1280: {
                    items: 3
                }
            }
        }
    )


}
