const $vacanciesItems = document.querySelectorAll('.vacancies__item')
const vacanciesItemActiveClass = 'vacancies__item--active'

$vacanciesItems.forEach(($vacanciesItem) => {
    $vacanciesItem.addEventListener('click', () => {
        if ($vacanciesItem.classList.contains(vacanciesItemActiveClass)) {
            closeItems()
        } else {
            closeItems()
            $vacanciesItem.classList.add(vacanciesItemActiveClass)
        }
    })
})

function closeItems() {
    $vacanciesItems.forEach(($vacanciesItem) => {
        $vacanciesItem.classList.remove(vacanciesItemActiveClass)
    })
}
