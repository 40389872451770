// Объект для прикрепления на window
window.browserOptions = {};

// Ширины вьюпорта
window.browserOptions.viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;

// Высота вьюпорта
window.browserOptions.viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;

// Позиция скролла
window.browserOptions.scrollTop = 0;

// Ширина скролл бара
window.browserOptions.scrollbarWidth = getScrollWidth();

// Доступность ховера
window.browserOptions.isHover = getHoverAvailability();

// Мобильный сафари
window.browserOptions.isMobileSafari = checkSafari();

// Большой мобильный брейкпойнт
window.browserOptions.bigMobileBreakpoint = 1279;

// Малый мобильный брейкпойнт
window.browserOptions.smallMobileBreakpoint = 666;

// Переопределение ширины и высоты при ресайзе
window.addEventListener('resize', () => {
    window.browserOptions.viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
    window.browserOptions.viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
});

// Переопределение переменной скролла при скролле
window.addEventListener('scroll', () => {
    window.browserOptions.scrollTop = window.scrollY;
});

// Функция определения ширины скролла
function getScrollWidth() {
    let div = document.createElement('div');
    let width = 0;
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    document.body.append(div);
    width = div.offsetWidth - div.clientWidth;
    div.remove();
    return width;
}

// Функция определения поддержки ховера
function getHoverAvailability() {
    let style = document.createElement('style');
    style.textContent = `
        :root {
            --hover-device: false;
        }
        @media (hover) {
            :root {
                --hover-device: true;
            }
        }
    `;

    document.body.append(style);
    let hover = getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue('--hover-device');

    return hover;
}

function checkSafari() {
    let ua = navigator.userAgent;
    let matches = ua.match(/iPhone|iPad/i);
    let safari = false;

    if (matches !== null) {
        let chrome = ua.match(/CriOS/i);

        if (!chrome) {
            safari = true;
        }
    }

    return safari;
}
