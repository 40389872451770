const $shortProductPreview = document.querySelector('.product-preview-short')

if ($shortProductPreview) {
    shortProductPreviews($shortProductPreview)
}

export function shortProductPreviews($first) {
    const $wrapper = $first.parentNode
    let productPreviews = $wrapper.querySelectorAll('.product-preview-short')

    let columnCount = 0
    let currentDesc = true

    init()

    function init() {
        addEventsListener()

        const gridComputedStyle = window.getComputedStyle($wrapper);
        columnCount = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').length;

        if (window.innerWidth > 1279) {
            if (!currentDesc) {
                currentDesc = true
                setOrders()
            }
        } else {
            if (currentDesc) {
                currentDesc = false
                setOrders()
            }
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 1279) {
                if (!currentDesc) {
                    currentDesc = true
                    setOrders()
                }
            } else {
                if (currentDesc) {
                    currentDesc = false
                    setOrders()
                }
            }
        })
    }

    function addEventsListener() {

        productPreviews.forEach(($productPreview) => {
            const $naturalInfo = $productPreview.nextElementSibling
            const $closeNaturalInfo = $naturalInfo.querySelector('.modal__close');
            const $naturalInfoInner = $naturalInfo.querySelector('.product-preview-short__natural-info-inner')

            $closeNaturalInfo.addEventListener('click', () => {
                closeNaturalInfo($naturalInfo, $naturalInfoInner)
            })

            $productPreview.addEventListener('click', () => {

                productPreviews.forEach(($otherProductPreview) => {
                    const $otherNaturalInfo = $otherProductPreview.nextElementSibling
                    const $otherNaturalInfoInner = $otherNaturalInfo.querySelector('.product-preview-short__natural-info-inner')

                    if ($otherNaturalInfo != $naturalInfo) {
                        closeNaturalInfo($otherNaturalInfo, $otherNaturalInfoInner)
                    }
                })

                const height = $naturalInfoInner.offsetHeight;
                $naturalInfo.style.height = `${height}px`
                $naturalInfo.style.position = 'relative'

                $naturalInfoInner.style.opacity = 1;
                $naturalInfoInner.style.visibility = 'visible';
            })

        })
    }

    function setOrders() {
        productPreviews.forEach(($productPreview, index) => {
            const $naturalInfo = $productPreview.nextElementSibling

            if (currentDesc) {
                $naturalInfo.style.order = (((index - (index % columnCount)) / columnCount) + 1) * columnCount * 10 - 5;
            } else {
                $naturalInfo.style.order = index * 10 + 1;
            }


        })
    }

    function closeNaturalInfo($naturalInfo, $naturalInfoInner) {
        $naturalInfo.style.height = `0px`
        $naturalInfoInner.style.opacity = 0;
        $naturalInfoInner.style.visibility = 'hidden';

        const transition = parseFloat(window.getComputedStyle($naturalInfo).transitionDuration) * 1000

        setTimeout(() => {
            $naturalInfo.style.position = 'absolute'
        }, transition)


    }

}

