let $filter = document.querySelector('.catalog-filter')
let $paginationItems = document.querySelectorAll('.pagination__item')

let filterData = {
    page: null,
    sort: null
};

filterData.page = getQueryVariable('page')
filterData.sort = getQueryVariable('sort')

function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
}

if ($filter) {
    $filter.addEventListener('change', () => {
        filterData.sort = $filter.value;
        routeWithFilters();
    });
}


if ($paginationItems.length > 0) {
    $paginationItems.forEach($paginationItem => {
        $paginationItem.addEventListener('click', () => {

            if ($paginationItem.classList.contains('pagination__item--active')) {
                return
            }

            filterData.page = $paginationItem.dataset.page;
            routeWithFilters();
        })
    })
}

function routeWithFilters() {
    let symb = '?';
    let params = '';
    if (filterData.page != null && filterData.page != 1) {
        symb = '&'
        params = '?page=' + filterData.page
    }

    if (filterData.sort != null) {
        params += symb + 'sort=' + filterData.sort
    }

    window.location = window.location.origin + window.location.pathname + params;
}



