const $searchInput = document.querySelector('.header__search-input');
const $searchButton = document.querySelector('.header__search-button');

if ($searchInput && $searchButton) {

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' && document.activeElement === $searchInput) {
            redirectSearch()
        }
    })

    $searchButton.addEventListener('click', redirectSearch)

    function redirectSearch() {
        let searchValue = $searchInput.value;

        if (searchValue === '') {
            return
        }

        let pathname = window.location.pathname;
        let hrefStep = pathname.split('/');
        let prefix = '';

        if (hrefStep[1] === 'ru') {
            prefix = '/ru';
        } else if (hrefStep[1] === 'en') {
            prefix = '/en';
        }

        window.location = window.location.origin + prefix + '/search/' + searchValue;
    }
}
